import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import React, {useState, useEffect} from 'react'
import Button from '@material-ui/core/Button';
import Sky from "react-sky"
import axios from '../common/axios'
import cakeSlice from  "../img/cake-slice.svg"
import cupcake from "../img/cup-cake.svg"
import cookie from "../img/cookie.svg"
import beans from "../img/coffee-beans.svg"
import cup from "../img/coffee-cup.svg"
import latte from "../img/latte.svg"
import macaron from "../img/macaron.svg"


// Choose the role: user or customer
function App() {
  const [lat,setLat] = useState('')
  const [lng,setLng] =  useState('')
  const [isVlogin, setVlogin] = useState('');
  
  //**get the location of the user */
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
        setLat(position.coords.latitude)
        setLng(position.coords.longitude)
    });
  }, [])

  /**check the vendor login states */
  useEffect(()=>{
    axios.post("/vendor/loginState",{token:localStorage.getItem("vjwtToken")}).then(response=>{
      if(response.data.status==="10000"){
        setVlogin(true)
      }
    })
  })

  localStorage.setItem("lat",lat)
  localStorage.setItem("lng",lng)

  const checkVlogin = () =>{
    if(isVlogin){
      return  <Link to="/Vendor/Location">
                <Button variant="contained" color="primary" size="large" style = {{marginLeft:"2vw",width:94.27  }}>Vendor</Button>
              </Link>

    }else{
      return <Link to="/Vendor/Login">
                <Button variant="contained" color="primary" size="large" style = {{marginLeft:"2vw",width:94.27  }}>Vendor</Button>
              </Link>

    }
  }

  return (
    // The Home Page
    <div>
      <Sky images={{
            0: cupcake,
            1: cakeSlice,
            2: cookie,
            3: latte,
            4: beans,
            5: macaron,
            6: cup,
          }}
          how={110} 
          time={20} 
          size={'100px'} 
          background={"#F5F5F5"}/>
          <div className="App" style={{position: 'absolute', left: '49.5%', top: '40%', transform: 'translate(-50%, -50%)',width:"100%"}}>
          <h1 style={{fontSize:"50px",fontWeight:"bold",textAlign :'center'}}>Welcome to Snacks In Van</h1>
              <p style = {{fontSize:"20px",textAlign :'center'}}>
                Snacks in Van runs a fleet of food trucks that work as popup cafes.
                <br></br> 
                Choose your identity to continue😎
                <br></br>
              {/* Customer role button  */}
              <Link to="/Customer/Location" >
                <Button variant="contained" color="primary" size="large">Customer</Button>
              </Link>
              {/* Vendor role button  */}
              {checkVlogin()}
              </p>

          </div>
    </div>

  );
}

export default App;
