import React, {useState} from 'react'
import { Link } from "react-router-dom";
import { Button} from "react-bootstrap";
import { Divider, Menu, Layout, Card, Form, message, Input, Switch} from 'antd';
import { MailOutlined, SettingOutlined} from '@ant-design/icons'
import axios from '../common/axios'
import LoginPart from "../components/LoginPart"


export default function UserProfile(props) {
    const [toggleCollapsed,setcollapsed]=useState(false)
    const [viewProfile,setViewProfile]=useState(true)
    const [changeProfile,setChangeProfile]=useState(false)
    const [changePassword,setChangePassword]=useState(false)
    const [changeEmail, setChangeEmail]=useState(false)
    const [changeName, setChangeName]=useState(false)
    const [changeFamily, setChangeFmaily]=useState(false)
    const [email,setEmail] = useState('')
    const [password,setPassword] = useState('')
    const [givenName,setGiven] = useState('')
    const [familyName,setFamily] = useState('')

    const { Content, Sider } = Layout;
    const { SubMenu } = Menu;
    const regexCheck = new RegExp("^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.{8,})")

    /**change the password if it meets the requirments */
    const onClick=()=>{
        if(regexCheck.test(password)){
            axios.post('/user/update/'+ localStorage.getItem("userID"),{givenName:givenName,familyName:familyName,email:email,password:password}).then((response)=>{
                if(response.data.success){
                    message.info("Your profile are update")
                    localStorage.setItem("name",givenName)
                    localStorage.setItem("familyname",familyName)
                    localStorage.setItem("email", email)
                    localStorage.setItem("password", password)
                    props.history.push('/Customer/Profile');
                }else{
                    message.error(response.data.error)
                }
            })
        }else{
            message.error("Your password doesn't match the requirments, please check it!")
        }

    }

    const changecollapsed=() =>{
        setcollapsed(!toggleCollapsed)
    }

    const switchToProfile=() =>{
        setViewProfile(true)
        setChangeProfile(false)
        setChangePassword(false)
    }

    const switchToChangeProfile=() =>{
        setViewProfile(false)
        setChangeProfile(true)
        setChangePassword(false)
        setPassword(localStorage.getItem("password"))
    }

    const switchToChangePassword=() =>{
        setViewProfile(false)
        setChangeProfile(false)
        setChangePassword(true)
        setGiven(localStorage.getItem("name"))
        setFamily(localStorage.getItem("familyname"))
        setEmail(localStorage.getItem("email"))
    }

    const no_changeEmail=() =>{
        setChangeEmail(!changeEmail)
        setEmail(localStorage.getItem("email"))
    }

    const no_changeName=() =>{
        setChangeName(!changeName)
        setGiven(localStorage.getItem("name"))
    }

    const no_changeFamily=() =>{
        setChangeFmaily(!changeFamily)
        setFamily(localStorage.getItem("familyname"))
    }

    /**rendor the page depands on different situations */
    const checkViews=()=>{
        if(viewProfile){
            return <Card title="User details">
                        <Card type="inner" title="Given Name">
                            {localStorage.getItem("name")}
                        </Card>
                        <Card type="inner" title="Family Name">
                            {localStorage.getItem("familyname")}
                        </Card>
                        <Card type="inner" title="Your Email">
                            {localStorage.getItem("email")}
                        </Card>
                    </Card>
        }
        else if(changePassword){
            return  <Card title="Change Password">
                        <Form>
                            <Form.Item label="Password" name="password" rules={[{required: true,message: 'Please input your password!',},]}
                            extra= "Your passowrd should contain at least one alphabet character, one numerical digit and at least 8 digits.">
                                <Input placeholder="Enter Your new password" onChange = {e=>setPassword(e.target.value)} />
                            </Form.Item>
                            <Button variant="primary" onClick={onClick}>
                                Confirm
                            </Button>
                        </Form>
                    </Card>        
        }
        else if(changeProfile){
            return  <Card title="Change Profile detail">
                        <Form>
                            <Form.Item label="Email" name="email" rules={[{required: true,message: 'Please input your Email!',},]}>
                                <Input disabled={changeEmail} placeholder="Enter Your new Email" onChange = {e=>setEmail(e.target.value)} />
                                <Switch  checkedChildren="not change" unCheckedChildren="change" onClick = {no_changeEmail}/>
                            </Form.Item>
                            <Form.Item label="GivenName" name="givenname" rules={[{required: true,message: 'Please input your givenname!',},]}>
                                <Input disabled={changeName} placeholder="Enter Your new givenname" onChange = {e=>setGiven(e.target.value)} />
                                <Switch  checkedChildren="not change" unCheckedChildren="change" onClick = {no_changeName}/>
                            </Form.Item>
                            <Form.Item label="Familyname" name="familyname" rules={[{required: true,message: 'Please input your familyname!',},]}>
                                <Input  disabled ={changeFamily}placeholder="Enter Your new familyname" onChange = {e=>setFamily(e.target.value)} />
                                <Switch  checkedChildren="not change" unCheckedChildren="change" onClick = {no_changeFamily}/>
                            </Form.Item>
                            <Button variant="primary" onClick={onClick}>
                                 Confirm
                            </Button>
                    </Form>
            </Card>
        }

    }


    return (
        <div className="Profile">
           <div>
                <Link to="/">
                    <Button variant="Link" style={{float:"Left",marginLeft:"5px",marginTop:"5px",fontWeight:"bold"}}>Back</Button>
                </Link>
                <LoginPart/>
                <Divider/>
            </div>
            <Layout style={{ minHeight: '100vh' }}>
                <Sider collapsible collapsed={toggleCollapsed} onCollapse={changecollapsed} theme="light">
                    <div className="logo" />
                    <Menu defaultSelectedKeys={['1']} mode="inline">
                        <Menu.Item key="1" icon={<MailOutlined />} onClick = {switchToProfile}>
                            User profile
                        </Menu.Item>
                        <SubMenu key="sub1" icon={<SettingOutlined/>} title="Account setting">
                            <Menu.Item key="2" onClick = {switchToChangePassword}>Change Password</Menu.Item>
                            <Menu.Item key="3" onClick = {switchToChangeProfile}>Change Profile details</Menu.Item>
                        </SubMenu>
                    </Menu>
                </Sider>
                <Layout className="site-layout">
                    <Content style={{ margin: '0 16px' }}>
                        {checkViews()}
                    </Content>
                </Layout>
            </Layout>
        </div>
    )
}