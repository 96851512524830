import React from 'react'
import { Link } from "react-router-dom";
import { Button} from "react-bootstrap";
import {Divider} from "antd"
import "antd/dist/antd.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Card } from 'antd';
import LoginPart from "../components/LoginPart"
import Sky from "react-sky"
import cookie from "../img/cookie.svg"
import beans2 from "../img/bean.svg"
import cup2 from "../img/cups.svg"
import smile from "../img/positive.svg"
import wow from "../img/yes.svg"
import checked from "../img/checked.svg"
import drink from "../img/drink.svg"
import yes from "../img/yes.svg"
// successfully created the order
export default function OrderDetails() {
    return (

        <div>
            <LoginPart/>
            <Divider/>
            <Sky images={{
            0: cookie,
            1: wow,
            2: beans2,
            3: drink,
            4: cup2,
            5: smile,
            6: yes,
            7: checked,
          }}
          how={100} 
          time={20} 
          size={'100px'} 
          background={"#F5F5F5"} />
            <div style={{position: 'absolute', left: '49.5%', top: '40%', transform: 'translate(-50%, -50%)',width:"100%"}}>
                <Card  style={{width:'100',borderRadius:"200px", backgroundColor:"transparent",borderColor:"transparent",textAlign :'center'}}>

                <h1 style={{fontSize:'60px'}}>You have placed an order successfully!</h1>

                <p>
                <Link to="/Customer/Menu" style ={{textAlign:"center"}}>
                    <Button variant="Link" style={{marginLeft:"10px",marginTop:"10px",fontWeight:"bold"}}>
                        <h1 style = {{fontSize:'20px'}}>Back To Menu</h1>
                    </Button>
                </Link>
                </p>
                </Card>
            </div>


        </div>
    )
}

